import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { BodyTypeOption } from '@/lib/schema/inventory/types';
import { BodyTypesByMakes } from '@/lib/schema/shared/types';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

interface SearchFilterBodyTypeProps extends SearchFilterGeneralProps {
  makesBodyTypes: BodyTypesByMakes;
}

const ALL_BODY_TYPE_OPTIONS: BodyTypeOption[] = [
  {
    icon: {
      iconId: IconIds.Suv,
      className: 'h-[24px] w-[61px]',
    },
    value: 'SUV',
  },
  {
    icon: {
      iconId: IconIds.Sedan,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Sedan',
  },
  {
    icon: {
      iconId: IconIds.Coupe,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Coupe',
  },
  {
    icon: {
      iconId: IconIds.Hatchback,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Hatchback',
  },
  {
    icon: {
      iconId: IconIds.Wagon,
      className: 'h-[24px] w-[61px] ',
    },
    value: 'Wagon',
  },
  {
    icon: {
      iconId: IconIds.Truck,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Truck',
  },
  {
    icon: {
      iconId: IconIds.Minivan,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Minivan',
  },
  {
    icon: {
      iconId: IconIds.Van,
      className: 'h-[24px] w-[61px]',
    },
    value: 'Van',
  },
];

const ALL_BODY_TYPES_BY_KEY = ALL_BODY_TYPE_OPTIONS.reduce(
  (acc, option) => {
    acc[option.value] = option.icon;
    return acc;
  },
  {} as Record<string, { iconId: IconIds; className: string }>
);

type FilterOptions = {
  active: BodyTypeOption[];
  disabled: BodyTypeOption[];
};

export const SearchFilterBodyTypeSection: React.FC<
  SearchFilterBodyTypeProps
> = ({ updateFilter, filters, makesBodyTypes }) => {
  const bodyFilters = filters.body || [];

  const [filterOptions, setFilterOptions] = useState({
    active: ALL_BODY_TYPE_OPTIONS,
    disabled: [] as BodyTypeOption[],
  } as FilterOptions);

  useEffect(() => {
    if (filters.make.length > 0) {
      const bodyTypeFilters = filters.make.reduce(
        (acc, make) => {
          const { listed, unlisted } = makesBodyTypes[make];
          acc.active.push(...listed);
          acc.disabled.push(...unlisted);
          return acc;
        },
        { active: [] as string[], disabled: [] as string[] }
      );

      // Remove duplicates
      bodyTypeFilters.active = Array.from(new Set(bodyTypeFilters.active));
      bodyTypeFilters.disabled = Array.from(new Set(bodyTypeFilters.disabled));

      const newFilterOptions = {
        active: bodyTypeFilters.active
          .map((body) => {
            if (ALL_BODY_TYPES_BY_KEY[body]) {
              return {
                icon: ALL_BODY_TYPES_BY_KEY[body],
                value: body,
              };
            }
            return null;
          })
          .filter((body) => body !== null) as BodyTypeOption[],
        disabled: bodyTypeFilters.disabled
          .map((body) => {
            if (ALL_BODY_TYPES_BY_KEY[body]) {
              return {
                icon: ALL_BODY_TYPES_BY_KEY[body],
                value: body,
              };
            }
            return null;
          })
          .filter((body) => body !== null) as BodyTypeOption[],
      };

      setFilterOptions(newFilterOptions);
    } else {
      setFilterOptions({
        active: ALL_BODY_TYPE_OPTIONS,
        disabled: [] as BodyTypeOption[],
      });
    }
  }, [filters, makesBodyTypes]);

  return (
    <section className="grid grid-cols-2 gap-l pt-s">
      {filterOptions.active.map((option, index) => {
        const active = bodyFilters.includes(option.value);
        const iconId = active
          ? `${option.icon.iconId}Solid`
          : option.icon.iconId;

        const newBody = active
          ? bodyFilters.filter((body) => body !== option.value)
          : [...bodyFilters, option.value];

        return (
          <div
            role="button"
            onClick={() => {
              updateFilter({
                fields: {
                  body: newBody,
                },
              });
            }}
            key={`${option.icon.iconId}-${index}`}
            aria-label={`filter by ${option.value}`}
            className={clsx(
              'flex cursor-pointer flex-col items-center justify-center gap-s rounded-small border p-l hover:border hover:border-neutral-400 hover:text-brandMidnight',
              {
                'border-brandElectric bg-green-light !text-brandMidnight':
                  active,
                'border-neutral-100 !bg-neutral-100 text-neutral-800': !active,
              }
            )}
          >
            <Icon
              iconId={iconId as IconIds}
              className={option.icon.className}
            />
            <div className="text-body1Light text-neutral-800">
              {option.value}
            </div>
          </div>
        );
      })}
      {filterOptions.disabled.map((option, index) => (
        <div
          key={`disabled-${option.icon.iconId}-${index}`}
          aria-label={`disabled filter by ${option.value}`}
          className={
            'flex flex-col items-center justify-center gap-s rounded-small border border-neutral-200 bg-neutral-0 p-l'
          }
        >
          <Icon
            iconId={`${option.icon.iconId}Disabled` as IconIds}
            className={option.icon.className}
          />
          <div className="text-body1Light text-neutral-600">{option.value}</div>
        </div>
      ))}
    </section>
  );
};
