import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import LocationAutoCompleteInput from '@/components/molecules/LocationAutoCompleteInput/LocationAutoCompleteInput';
import { useZipContext } from '@/context/ZipContext';
import { useZipcode } from '@/hooks/useZipcode';
import { browserLocation } from '@/lib/browserLocation';
import { getValidDistanceFilterValueOrDefault } from '@/lib/searchFilters';
import { FilterType, UpdateFilterActionFields } from '@/types/filters';
import { useApiIsLoaded as useGoogleMapsApiIsLoaded } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export interface SearchFilterControlsProps {
  filters: FilterType;
  updateFilter: (body: UpdateFilterActionFields) => void;
  setIsGridView: (isGridView: boolean) => void;
  isGridView: boolean;
  setZipCode: (zip: string) => void;
  zipCode: string;
}

export const SearchFilterControls: React.FC<SearchFilterControlsProps> = ({
  filters,
  updateFilter,
  setZipCode,
  zipCode,
}) => {
  const [errorMessage, setErrorMessage] = useState('Enter a U.S. Zip Code');
  const [postalCodeInvalid, setPostalCodeInvalid] = useState(false);
  const { region: country } = useZipcode();
  const isGooglePlacesLibraryLoaded = useGoogleMapsApiIsLoaded();

  const { setCustomZip } = useZipContext();

  useEffect(() => {
    if (filters.postal) {
      setZipCode(filters.postal);
    }
  }, [filters.postal, setZipCode]);

  const handlePostalCodeChange = (val: string, address?: string) => {
    if (val === filters.postal) {
      updateFilter({
        fields: {
          postal: val,
        },
        refetchOnUpdate: false,
      });
      setPostalCodeInvalid(false);
      setErrorMessage('');
      return;
    }
    const regex = /^\d{5}$/;
    if (val && regex.test(val)) {
      fetch(
        `/api/geo/geocode?${new URLSearchParams({ postalCode: val, country })}`
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error();
        })
        .then((geoData) => {
          if (geoData.country !== 'US') {
            throw new Error();
          }

          setPostalCodeInvalid(false);
          setErrorMessage('');
          setCustomZip(val, address);
          updateFilter({
            fields: {
              postal: val,
            },
          });
        })
        .catch(() => {
          setPostalCodeInvalid(true);
          setErrorMessage(
            'The zipcode you entered does not belong in the United States.'
          );
        });
    } else if (val === '') {
      setPostalCodeInvalid(false);
      updateFilter({
        fields: {
          postal: val,
        },
      });
      setErrorMessage('');
    } else {
      setPostalCodeInvalid(true);
      setErrorMessage('Enter a U.S. Zip Code');
    }
  };

  return (
    <section className="flex w-full flex-col justify-between ml:flex-row ml:gap-4">
      <section className="flex w-full flex-col gap-l">
        <LocationAutoCompleteInput
          label="City or Zip code"
          message={errorMessage}
          zipCode={zipCode}
          setZip={setZipCode}
          useGeolocation={() => {
            browserLocation()
              .then(({ zip }) => {
                if (zip) {
                  handlePostalCodeChange(zip);
                }
              })
              .catch(() => {
                setPostalCodeInvalid(true);
                setErrorMessage('Not a valid US zipcode.');
              });
          }}
          onBlur={handlePostalCodeChange}
          onAutoComplete={handlePostalCodeChange}
          invalid={postalCodeInvalid}
          isGooglePlacesLibraryLoaded={isGooglePlacesLibraryLoaded}
        />
        <FormSelect
          label="Distance"
          className="min-w-[125px]"
          placeholder="Select range"
          variant={DropdownMenuItemVariants.SingleSelect}
          options={[
            ...['50', '100', '150', '200'].map((value) => ({
              text: `${value}mi`,
              value,
            })),
            {
              text: 'Nationwide',
              value: 'nationwide',
            },
          ]}
          value={[filters.distance]}
          onChange={([value]) => {
            updateFilter({
              fields: {
                distance: getValidDistanceFilterValueOrDefault(value),
              },
            });
          }}
        />
      </section>
    </section>
  );
};
