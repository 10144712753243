import Accordion from '@/components/molecules/Accordion';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { DropdownMenuOptionType } from '@/components/molecules/DropdownMenu';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import { BodyTypesByMakes } from '@/lib/schema/shared/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchFilterBodyTypeSection } from '../SearchFilterBodyTypeSection.tsx/SearchFilterBodyTypeSection';
import {
  SearchFilterChipsSection,
  SearchFilterChipsSectionProps,
} from '../SearchFilterChipsSection/SearchFilterChipsSection';
import { SearchFilterConditionAndFuelSection } from '../SearchFilterConditionAndFuelSection/SearchFilterConditionAndFuelSection';
import {
  SearchFilterControls,
  SearchFilterControlsProps,
} from '../SearchFilterControls/SearchFilterControls';
import { SearchFilterEVSavingsSection } from '../SearchFilterEVSavingsSection/SearchFilterEVSavingsSection';
import { SearchFilterFeatureSection } from '../SearchFilterFeatureSection/SearchFilterFeatureSection';
import { SearchFilterGeneralInfoSection } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';
import { SearchFilterPriceSection } from '../SearchFilterPriceSection/SearchFilterPriceSection';
import { SearchFilterSellerTypeSection } from '../SearchFilterSellerTypeSection/SearchFilterSellerTypeSection';

export interface SearchFiltersProps
  extends Omit<
      SearchFilterChipsSectionProps,
      'handleUpdateMake' | 'makesToModels' | 'setZipCode'
    >,
    Omit<SearchFilterControlsProps, 'setZipCode' | 'zipCode'> {
  makes: DropdownMenuOptionType[];
  features: DropdownMenuOptionType[];
  onSearch: () => void;
  setIsListedPrice: (isListedPrice: boolean) => void;
  isListedPrice: boolean;
  makesBodyTypes: BodyTypesByMakes | null;
  setIsClearingSearchInput: (isClearingSearchInput: boolean) => void;
}

type ExpandedKey =
  | 'Location'
  | 'Make and Model'
  | 'Price'
  | 'Condition and Mileage'
  | 'Feature'
  | 'Seller type'
  | 'Body type';

const SearchFilters: React.FC<SearchFiltersProps> = ({
  filters,
  makes,
  features,
  clearFilters,
  updateFilter,
  onSearch,
  isListedPrice,
  setIsListedPrice,
  setIsGridView,
  setFilterChipsList,
  isGridView,
  onSortChange,
  sort,
  data,
  makesBodyTypes,
  setIsClearingSearchInput,
}) => {
  const [models, setModels] = React.useState<DropdownMenuOptionType[]>([]);
  const [makesToModels, setMakesToModels] = useState<Record<string, string[]>>(
    {}
  );
  const { settings: vehicleFilterSettings } = useVehicleFilterSettingsContext();

  const initialExpandedState = {
    Location: true,
    'Make and Model': false,
    Price: false,
    'EV Savings': true,
    'Condition and Mileage':
      filters.condition !== '' ||
      filters.fuelType !== '' ||
      filters.maxMileage !== '',
    Feature: filters.features.length > 0,
    'Seller type': filters.dealer_group !== '',
    'Body type': filters.body.length > 0,
  };

  const [expandedStates, setExpandedStates] = useState(
    () => initialExpandedState
  );
  useEffect(() => {
    if (vehicleFilterSettings.evSavings === false) {
      setExpandedStates((prev) => ({
        ...prev,
        'EV Savings': false,
      }));
    } else if (vehicleFilterSettings.evSavings === true) {
      setExpandedStates((prev) => ({
        ...prev,
        'EV Savings': true,
      }));
    }
  }, [vehicleFilterSettings]);
  const [zipCode, setZipCode] = useState<string>('');

  const numberOfChips = useMemo(
    () => ({
      Location: 0,
      'Make and Model':
        filters.make.length +
        filters.model.length +
        (filters.year_start ? 1 : 0) +
        (filters.year_end ? 1 : 0),
      Price:
        (filters.price_start ? 1 : 0) +
        (filters.price_end ? 1 : 0) +
        (filters.has_ev_incentives ? 1 : 0),
      'EV Savings':
        (vehicleFilterSettings.displayIncentives ? 1 : 0) +
        (vehicleFilterSettings.displayFuelSavings ? 1 : 0),
      'Condition and Mileage':
        (filters.condition ? filters.condition.split(',').length : 0) +
        (filters.fuelType ? filters.fuelType.split(',').length : 0) +
        (filters.maxMileage ? 1 : 0),
      Feature: filters.features.length,
      'Seller type': filters.dealer_group
        ? filters.dealer_group.split(',').length
        : 0,
      'Body type': filters.body.length,
    }),
    [filters, vehicleFilterSettings]
  );

  const fetchModels = async (query: string) => {
    try {
      const response = await fetch(
        `/api/inventory/listings/models?makes=${query}`
      );
      if (response.ok) {
        const modelData: Record<string, string[]> = await response.json();
        setMakesToModels(modelData);
        const fetchModelsData: DropdownMenuOptionType[] = [];
        Object.values(modelData).forEach((modelList) => {
          modelList.forEach((model) => {
            fetchModelsData.push({ text: model, value: model });
          });
        });
        setModels(fetchModelsData.sort((a, b) => a.text.localeCompare(b.text)));
      }
    } catch (err) {
      setModels([]);
    }
  };

  const handleUpdateMake = useCallback(
    (newMakes: string[]) => {
      const newModelList = Object.keys(makesToModels)
        .filter((make) => newMakes.includes(make))
        .map((make) => makesToModels[make])
        .flat();

      updateFilter({
        fields: {
          make: newMakes,
          model: filters.model.filter((modelName) =>
            newModelList.includes(modelName)
          ),
        },
      });
    },
    [filters.model, makesToModels, updateFilter]
  );

  const handleUpdateModel = useCallback(
    (newModel: string[]) => {
      updateFilter({
        fields: {
          model: newModel,
        },
      });
    },
    [updateFilter]
  );

  React.useEffect(() => {
    if (filters.make.length > 0) {
      fetchModels(filters.make.join(','));
    }
  }, [filters.make]);

  const filtersAccordionGroup = [
    {
      title: 'Location',
      children: (
        <SearchFilterControls
          filters={filters}
          updateFilter={updateFilter}
          setIsGridView={setIsGridView}
          isGridView={isGridView}
          setZipCode={setZipCode}
          zipCode={zipCode}
        />
      ),
      condition: true,
      expanded: expandedStates.Location,
      numberOfChips: numberOfChips.Location,
    },
    {
      title: 'Make and Model',
      children: (
        <SearchFilterGeneralInfoSection
          updateFilter={updateFilter}
          filters={filters}
          makes={makes}
          models={models}
          handleUpdateMake={handleUpdateMake}
          handleUpdateModel={handleUpdateModel}
        />
      ),
      condition: true,
      expanded: expandedStates['Make and Model'],
      numberOfChips: numberOfChips['Make and Model'],
    },
    {
      title: 'Price',
      children: (
        <SearchFilterPriceSection
          updateFilter={updateFilter}
          filters={filters}
          isListedPrice={isListedPrice}
          setIsListedPrice={setIsListedPrice}
        />
      ),
      condition: true,
      expanded: expandedStates.Price,
      numberOfChips: numberOfChips.Price,
    },
    {
      title: 'EV Savings',
      children: <SearchFilterEVSavingsSection />,
      condition: true,
      expanded: expandedStates['EV Savings'],
      numberOfChips: numberOfChips['EV Savings'],
    },
    {
      title: 'Condition and Mileage',
      children: (
        <SearchFilterConditionAndFuelSection
          updateFilter={updateFilter}
          filters={filters}
        />
      ),
      condition: true,
      expanded: expandedStates['Condition and Mileage'],
      numberOfChips: numberOfChips['Condition and Mileage'],
    },
    {
      title: 'Feature',
      children: (
        <SearchFilterFeatureSection
          updateFilter={updateFilter}
          filters={filters}
          features={features}
        />
      ),
      condition: process.env.NEXT_PUBLIC_ENABLE_BODY_FEATURE_FILTER === 'true',
      expanded: expandedStates.Feature,
      numberOfChips: numberOfChips.Feature,
    },
    {
      title: 'Seller type',
      children: (
        <SearchFilterSellerTypeSection
          updateFilter={updateFilter}
          filters={filters}
        />
      ),
      condition: true,
      expanded: expandedStates['Seller type'],
      numberOfChips: numberOfChips['Seller type'],
    },
  ];
  if (makesBodyTypes !== null) {
    filtersAccordionGroup.push({
      title: 'Body type',
      children: (
        <SearchFilterBodyTypeSection
          filters={filters}
          updateFilter={updateFilter}
          makesBodyTypes={makesBodyTypes}
        />
      ),
      condition: true,
      expanded: expandedStates['Body type'],
      numberOfChips: numberOfChips['Body type'],
    });
  }

  const handleSetExpanded = (key: ExpandedKey) => {
    setExpandedStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const totalFilterChips = useMemo(() => {
    const totalChips = Object.values(numberOfChips).reduce(
      (acc, curr) => acc + curr,
      0
    );
    return totalChips;
  }, [numberOfChips]);

  return (
    <>
      <article className="mb-[90px] flex flex-col gap-xl bg-neutral-0 px-l ml:px-0">
        <SearchFilterChipsSection
          makesToModels={makesToModels}
          handleUpdateMake={handleUpdateMake}
          updateFilter={updateFilter}
          filters={filters}
          clearFilters={clearFilters}
          onSortChange={onSortChange}
          sort={sort}
          data={data}
          setFilterChipsList={setFilterChipsList}
          setIsClearingSearchInput={setIsClearingSearchInput}
          setZipCode={setZipCode}
        />
        <div className="flex items-center gap-s ml:hidden">
          <h4 className="text-h3Regular text-neutral-900">Filters</h4>
          {totalFilterChips > 0 && (
            <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
              {totalFilterChips}
            </div>
          )}
        </div>
        {filtersAccordionGroup.map((filterItem, index) => {
          if (filterItem.condition) {
            return (
              <div key={`${filterItem.title}_${index}`}>
                <Accordion
                  className="mb-xl"
                  buttonClassName="!items-end"
                  title={
                    <div className="mt-s flex items-center gap-l text-body1Regular text-neutral-800 ml:text-body1Medium">
                      <div>{filterItem.title}</div>
                      {filterItem.title === 'EV Savings' && <EVSavingsToggle />}

                      {filterItem.numberOfChips > 0 && (
                        <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
                          {filterItem.numberOfChips}
                        </div>
                      )}
                    </div>
                  }
                  expanded={
                    !vehicleFilterSettings.evSavings &&
                    filterItem.title === 'EV Savings'
                      ? false
                      : filterItem.expanded
                  }
                  setExpanded={() => {
                    handleSetExpanded(filterItem.title as ExpandedKey);
                  }}
                  icon={
                    !vehicleFilterSettings.evSavings &&
                    filterItem.title === 'EV Savings'
                      ? null
                      : { id: 'chevron', variant: 'small' }
                  }
                >
                  {filterItem.children}
                </Accordion>
                <hr className="mt-l border-[1px] border-neutral-200" />
              </div>
            );
          }
          return null;
        })}
      </article>
      <div className="safe-bottom fixed bottom-0 z-10 w-full justify-center pb-xl ml:hidden">
        <div className="flex w-full px-m m:px-0">
          <Button
            aria-label="Apply filters"
            onClick={onSearch}
            variant={ButtonVariants.Primary}
            background="bg-blue-light"
            shadow={'shadow-ml'}
          >
            Apply filters
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchFilters;
