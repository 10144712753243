import { FilterType } from '@/types/filters';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

const usePreviousPageData = (
  showRebates: boolean,
  initialFilters: FilterType
) => {
  const router = useRouter();
  const [isGridView, setIsGridView] = useState(true);
  const [isListedPrice, setIsListedPrice] = useState(
    initialFilters.price_end === '' && initialFilters.price_start === ''
  );

  const savePreviousData = useCallback(
    (url: string) => {
      const scrollPos = window.scrollY;
      sessionStorage.setItem(
        `scrollPos:${url}`,
        JSON.stringify({
          scrollPos,
          isGrid: isGridView,
          isListed: isListedPrice,
        })
      );
    },
    [isGridView, isListedPrice]
  );

  const restorePreviousData = (url: string) => {
    const restoreData = sessionStorage.getItem(`scrollPos:${url}`) || '{}';
    const { scrollPos, isGrid, isListed } = JSON.parse(restoreData);
    setIsGridView(isGrid);
    setIsListedPrice(isListed);
    if (scrollPos !== null) {
      setTimeout(() => {
        window.scrollTo(0, Number(scrollPos));
      }, 100);
      sessionStorage.removeItem(`scrollPos:${url}`);
    }
  };

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const handleRouteChangeStart = () => {
        savePreviousData(router.asPath);
      };

      const handleRouteChangeComplete = (url: string) => {
        const isBackNavigation = sessionStorage.getItem('isBackNavigation');
        if (isBackNavigation) {
          restorePreviousData(url);
        } else {
          sessionStorage.removeItem(`scrollPos:${url}`);
        }
        sessionStorage.removeItem('isBackNavigation');
      };

      router.beforePopState(() => {
        sessionStorage.setItem('isBackNavigation', 'true');
        return true;
      });

      router.events.on('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);

      return () => {
        router.events.off('routeChangeStart', handleRouteChangeStart);
        router.events.off('routeChangeComplete', handleRouteChangeComplete);
      };
    }
    return () => {};
  }, [router, savePreviousData]);

  return {
    isGridView,
    setIsGridView,
    isListedPrice,
    setIsListedPrice,
  };
};

export default usePreviousPageData;
