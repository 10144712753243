import React, { ReactNode } from 'react';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import TextButton from '@/components/atoms/legacy/TextButton';
import { IconIds } from '@/components/atoms/legacy/Icon';
import ClientPortal from '@/components/molecules/ClientPortal';
import { ScComponent } from '@/types/interfaces';
import ShareLinkModal from '@/components/organisms/ShareLinkModal';
import copyToClipboard from '@/lib/copyToClipboard';

export interface PageHeaderProps extends ScComponent {
  label: string;
  hideActions?: boolean;
  customButton?: ReactNode;
  headingClassName?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  className = '',
  headingClassName = '',
  label,
  hideActions = false,
  customButton,
}) => {
  const [copied, setCopied] = React.useState(false);
  const [openShareModal, setOpenShareModal] = React.useState(false);

  const copyUrlToClipboard = () => {
    copyToClipboard(window.location.href);
    setCopied(true);
  };

  return (
    <section className={`flex flex-wrap items-center ${className}`}>
      <Heading
        className={`mb-[10px] w-full text-h3Regular text-main text-neutral-900 l:mb-0 l:w-auto ${headingClassName}`}
        level={HeadingLevels.H1}
      >
        {label}
      </Heading>
      {!hideActions && (
        <>
          <TextButton
            className={`ml-auto mr-[20px] flex items-start text-micro1 text-[#343434] ${
              copied ? 'border-b-2 border-b-electricGreen-70' : ''
            }`}
            prependIcon={IconIds.Copy}
            onClick={copyUrlToClipboard}
          >
            {!copied ? 'Copy Link' : 'Copied!'}
          </TextButton>
          <TextButton
            className="flex items-start text-micro1 text-[#343434]"
            prependIcon={IconIds.Share}
            onClick={() => setOpenShareModal(true)}
          >
            Share
          </TextButton>
          <ClientPortal
            className="max-w-[800px] rounded-[16px]"
            open={openShareModal}
            closeFn={() => setOpenShareModal(false)}
          >
            <ShareLinkModal className="rounded-[16px]" />
          </ClientPortal>
          {customButton}
        </>
      )}
    </section>
  );
};

export default PageHeader;
