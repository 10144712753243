import { SavedVehicles } from '@/lib/schema/user/types';
import clsx from 'clsx';
import { FC } from 'react';
import {
  CustomOrderCardProps,
  CustomOrderListingCard,
} from '../CustomOrderListingCard/CustomOrderListingCard';

interface VLPCustomOrderCardListProps {
  cardData: CustomOrderCardProps[];
  isGridView: boolean;
  estFuelSavingsByType: Record<string, { savings: number }> | null;
  isListedPrice: boolean;
  location: string;
  savedVehicles: SavedVehicles | null;
  isMobile: boolean;
}
export const VLPCustomOrderCardList: FC<VLPCustomOrderCardListProps> = ({
  cardData,
  isGridView,
  estFuelSavingsByType,
  isListedPrice,
  location,
  savedVehicles,
  isMobile,
}) => {
  return (
    <div className="flex w-full flex-col gap-l">
      <div className="flex flex-col items-center gap-s ml:items-start">
        <div className="text-h4SemiBold text-neutral-800">
          Custom Order a new EV
        </div>
        <div className="block text-body1Regular text-neutral-800">
          Looking for something specific? Custom Order your vehicle below.
        </div>
      </div>
      <div
        className={clsx('grid grid-cols-1 gap-xl', {
          'md:grid-cols-2 l:grid-cols-3': isGridView,
        })}
      >
        {cardData.map((card) => {
          const isSaved = !!savedVehicles?.build?.[card.id];

          return (
            <CustomOrderListingCard
              key={`custom-order-card-${card.model}-${card.make}-${card.year}`}
              {...card}
              isSaved={isSaved}
              isGridView={isGridView}
              estFuelSavingsByType={estFuelSavingsByType}
              isListedPrice={isListedPrice}
              location={location}
              isMobile={isMobile}
            />
          );
        })}
      </div>
    </div>
  );
};
